import { useState, useEffect } from 'react'

interface MediaQueryProps {
	[key: string]: string
}

const useMediaQuery = (breakpoints: MediaQueryProps) => {
	const [queryMatches, setQueryMatches] = useState(false)

	useEffect(() => {
		const mediaQueryLists: MediaQueryList[] = Object.keys(breakpoints).map(
			breakpoint =>
				window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`),
		)

		const checkMediaQueries = () => {
			const matches = mediaQueryLists.some(mediaQuery => mediaQuery.matches)
			setQueryMatches(matches)
		}

		mediaQueryLists.forEach(mediaQuery => {
			mediaQuery.addEventListener('change', checkMediaQueries)
		})

		// Initial check
		checkMediaQueries()

		return () => {
			mediaQueryLists.forEach(mediaQuery => {
				mediaQuery.removeEventListener('change', checkMediaQueries)
			})
		}
	}, [breakpoints])

	return queryMatches
}

export default useMediaQuery
